import { LogLevel } from "@azure/msal-browser"

/**
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
	names: {
		signUpSignIn: window._env_.REACT_APP_B2C_POLICY_SIGNIN,
		forgotPassword: window._env_.REACT_APP_B2C_POLICY_FORGOT_PASSWORD,
		editProfile: window._env_.REACT_APP_B2C_POLICY_EDIT_PROFILE,
	},
	authorities: {
		signUpSignIn: {
			authority: window._env_.REACT_APP_B2C_SIGNIN_URL,
		},
		// these aren't currently used
		// forgotPassword: {
		// 	authority: window._env_.REACT_APP_B2C_FORGOT_PASSWORD_URL,
		// },
		// editProfile: {
		// 	authority: window._env_.REACT_APP_B2C_EDIT_PROFILE_URL,
		// },
	},
	authorityDomain: window._env_.REACT_APP_B2C_POLICY_DOMAIN,
}

/**
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
	auth: {
		clientId: window._env_.REACT_APP_CLIENT_ID,
		authority: b2cPolicies.authorities.signUpSignIn.authority,
		knownAuthorities: [b2cPolicies.authorityDomain],
		redirectUri: window._env_.REACT_APP_REDIRECT_URL,
		postLogoutRedirectUri: window._env_.REACT_APP_POST_LOGOUT_URL,
		navigateToLoginRequestUrl: false,
	},
	cache: {
		cacheLocation: "sessionStorage",
		storeAuthStateInCookie: false,
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return
				}
				switch (level) {
					case LogLevel.Error:
						//console.error(message)
						return
					case LogLevel.Info:
						//console.info(message)
						return
					case LogLevel.Verbose:
						//console.debug(message)
						return
					case LogLevel.Warning:
						//console.warn(message)
						return
					default:
						return
				}
			},
		},
	},
}

/**
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
	scopes: [window._env_.REACT_APP_LOGIN_REQUEST_SCOPE],
}
